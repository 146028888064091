import axios from '../libs/axios';
// 预场次信息
const preMatchList = (data) => {
  return axios.get('/api/trd/preMatch/matchList', data);
};
// 校验场次信息
const checkCar = (data) => {
  return axios.get('/api/trd/preMatch/checkCar', data);
};
// 预选座
const updateCarStatus = (data) => {
  return axios.get('/api/trd/preMatch/updateCarStatus', data);
};
// 释放选择座位
const releaseCar = (data) => {
  return axios.get('/api/trd/preMatch/releaseCar', data);
};
// 购票成功
const updatePrinted = (data) => {
  return axios.get('/api/trd/preMatch/updatePrinted', data);
};
// 打印票据
const queryPrintInfo = (data) => {
  return axios.get('/api/trd/preMatch/queryPrintInfo', data);
};

export {
  preMatchList,
  checkCar,
  updateCarStatus,
  releaseCar,
  updatePrinted,
  queryPrintInfo
};

<template>
  <div class="bill-print" ref="billitem">
    <div class="bill-main">
      <h6 class="bill-main--title">欢迎光临{{data.shopName}}</h6>
      <ul class="bill-info line">
        <li class="bill-info__item">
          <span class="bill-info__item-title">交易编号:</span>{{data.orderId}}
        </li>
        <li class="bill-info__item">
          <span class="bill-info__item-title">交易时间:</span>{{data.orderTime}}
        </li>
      </ul>
      <ul class="bill-info line">
        <li class="bill-info__item">
          <span class="bill-info__item-title">充值金额:</span>{{data.amountPay}} 元
        </li>
        <li class="bill-info__item" v-if="data.amountDiscount">
          <span class="bill-info__item-title">优惠金额:</span>{{data.amountDiscount}} 元
        </li>
        <li class="bill-info__item">
          <!--<span class="bill-info__item-title">原余额:</span>{{data.memberInfo.amount - data.amount}}-->
        </li>
        <li class="bill-info__item">
          <span class="bill-info__item-title">支付信息:</span>
          <template v-for="item in data.payItems">
            <span :key="item.orderPayId" class="mr-10">{{item.payMethodName}}{{item.amountPaid}} 元</span>
          </template>
        </li>
        <li class="bill-info__item">
          <span class="bill-info__item-title">会员卡号:</span>{{data.mobile}}
        </li>
        <li class="bill-info__item">
          <span class="bill-info__item-title">账户余额:</span>{{data.memberInfo.amount}} 元
        </li>
      </ul>
      <div class="bill-info__tips line">
        <div>谢谢惠顾，欢迎下次光临!</div>
      </div>
    </div>
  </div>
</template>

<script>
  import PrintJs from '@/libs/print';
  import { rechargeInfo } from '@/api/recharge';
  import QRCode from 'qrcode';

  export default {
    name: 'rechargeBill',
    components: {},
    props: {},
    data() {
      return {
        data: {
          memberInfo: {}
        }
      };
    },
    computed: {},
    watch: {},
    created() {
    },
    mounted() {
    },
    destroyed() {
    },
    methods: {
      handlePrintBill(orderId) {
        rechargeInfo(orderId).then((res) => {
          this.data = res;
          this.$nextTick(() => {
            PrintJs(this.$refs.billitem);
          });
        });
      },
    },
  };
</script>

<style lang="less" scoped>

  .bill-print {
    display: none;
  }
  @media print {
    .bill-print {
      display: block;
    }
  }

  @page {
    /* 纵向打印 */
    size: portrait;
    margin: 0;
    padding: 0;
  }

  .page-break {
    page-break-after: always;
  }

  .bill-print {
    font-size: 12px;
  }
  .bill-info {
    color: #000;
    list-style: none;
  }
  .bill-info__item {
  }
  .bill-info__item-title {
    word-break: break-word;
    width: 200px;
  }

  .line:before {
    content: '';
    display: block;
    margin-top: 10px;
    border: 1px dashed #000;
  }

  .bill-main,
  .bill-main--title,
  table,
  table th,
  table td,
  .bill-sub,
  .bill-info__item-title,
  .bill-info__tips {
    font-size: 12px;
    color: #000;
  }
</style>
